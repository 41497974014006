<template>
  <div class="store-badges-container">
    <a class="store-badge" :style="{height: scale * 63 + 'px'}" @click="appStoreClick">
      <img class="badge-image" src="@/assets/badges/app-store-badge.png" :style="{maxHeight: scale * 63 + 'px'}"/>
    </a>
   <!-- <div class="spacer" />
    <a class="store-badge" :style="{height: scale * 63 + 'px'}">
      <img class="badge-image" src="@/assets/badges/play-store-badge.png" :style="{maxHeight: scale * 63 + 'px'}"/>
    </a>-->
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
export default {
  name: "StoreBadges",
  props: {
    scale: {
      type: Number,
      default: 1
    }
  },
  setup() {
    const getParameterByName = (name, url = window.location.href) => {
      name = name.replace(/[\[\]]/g, '\\$&');
      const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
          results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return '';
      return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }
    const appStoreLink = ref("https://app.adjust.com/rl0kckg")
    const resizeListener = () => {
      if(window.innerWidth < 992) {
        appStoreLink.value = "https://app.adjust.com/4sjupsn"
      } else {
        appStoreLink.value =  "https://app.adjust.com/rl0kckg"
      }
    }
    const appStoreClick = () => {
      const appStoreClickSent = localStorage.getItem('ascs');
      const marketingData = {
        "pixel_id": "463746421489324",
        "utm_source": getParameterByName('utm_source'),
        "utm_medium": getParameterByName('utm_medium'),
        "utm_campaign": getParameterByName('utm_campaign'),
        "utm_term": getParameterByName('utm_term'),
        "utm_content": getParameterByName('utm_content'),
        "fbclid": getParameterByName('fbclid'),
        "ua": navigator.userAgent,
        "resolution": screen.width+""+screen.height+""+window.devicePixelRatio
      }
      if (marketingData.utm_source && (!appStoreClickSent || Number(appStoreClickSent) < Date.now()-2*3600000)){
        fetch('/click', {
          method: 'POST', // or 'PUT'
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(marketingData)
        }).then(() => {
          localStorage.setItem('ascs',Date.now())
          window.open(appStoreLink.value, '_blank')
        }).catch(() => {
          window.open(appStoreLink.value, '_blank')
        })
      }else{
        window.open(appStoreLink.value, '_blank')
      }
    }

    onMounted(() => {
      resizeListener()
      window.addEventListener("resize", resizeListener)
    })
    return {
      appStoreClick
    }
  }
}
</script>

<style scoped>

.store-badges-container {
  padding: 15px 0;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.store-badge {
  text-decoration: none;
  cursor: pointer;
  padding: 0;
  pointer-events: all;
  max-width: 100%;
}

.badge-image {
  max-width: 100%;
}

.spacer {
  width: 20px;
}

</style>