<template>
  <div class="generic-input-wrapper">
    <div class="input-container">
      <label ref="labelRef" class="generic-input-label">
        {{ $t(label) }}
      </label>
      <div class="generic-input-container" ref="inputRef">
        <textarea class="generic-input" :maxlength="500" :value="modelValue" @focus="inputFocused" @input="$emit('update:modelValue', $event.target.value)" :name="name" :cols="cols" @blur="inputBlurred" />
      </div>
      <span ref="counterRef" class="character-counter">{{modelValue.length + "/500"}}</span>
    </div>
      <span class="input-error-text" ref="errorRef">{{ $t(errorText)}}</span>
  </div>
</template>

<script>
import { ref } from "vue";
export default {
  name: "GenericTextArea",
  props: {
    label: {
      type: String,
      default: "Input Label"
    },
    type: {
      type: String,
      default: "text"
    },
    name: {
      type: String,
      default: "a"
    },
    modelValue: {
      type: String,
      default: ""
    },
    rows: {
      type: Number,
      default: 50
    },
    cols: {
      type: Number,
      default: 40
    },
    color: {
      type: String,
      default: "black"
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const inputRef = ref(null);
    const errorRef = ref(null);
    const labelRef = ref(null);
    const counterRef = ref(null);

    const isError = ref(false);
    const errorText = ref("INVALID_EMPTY_FIELD");
    function validate() {
      if(props.required) {
        if(!props.modelValue) {
          errorText.value = "INVALID_EMPTY_FIELD";
          return putError();
        }
      }
      removeError();
    }
    function putError() {
      isError.value = true;
      inputRef.value.classList.add("generic-input-container-error")
      labelRef.value.classList.add("generic-input-label-error")
      counterRef.value.classList.add("character-counter-error")
      errorRef.value.style.visibility = "visible"
    }

    function removeError() {
      isError.value = false
      inputRef.value.classList.remove("generic-input-container-error")
      labelRef.value.classList.remove("generic-input-label-error")
      counterRef.value.classList.remove("character-counter-error")
      errorRef.value.style.visibility = "hidden"
    }

    function inputFocused() {
      isError.value = false;
      //const width = labelRef.value.clientWidth * 0.8 + 20
      //inputRef.value.style.clipPath = "polygon(0 0, 10px 0,10px 1px," + (width) + "px 1px," + (width) + "px 0, 100% 0, 100% 100%, 0 100%)";
      inputRef.value.classList.add("generic-input-container-focus")
      labelRef.value.classList.add("generic-input-label-shrink")
      labelRef.value.classList.add("generic-input-label-focus")
      labelRef.value.classList.remove("generic-input-label-error")
      counterRef.value.classList.remove("character-counter-error")

    }

    function inputBlurred() {
      validate()
      inputRef.value.classList.remove("generic-input-container-focus")
      labelRef.value.classList.remove("generic-input-label-focus")
      if(!props.modelValue) {
        labelRef.value.classList.remove("generic-input-label-shrink")
      }
    }

    return {
      inputBlurred,
      validate,
      isError,
      errorRef,
      inputRef,
      labelRef,
      counterRef,
      errorText,
      inputFocused
    }
  }
}
</script>

<style scoped>
textarea {
  resize: none;
}
.generic-input-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.generic-input-label {
  position: absolute;
  top: 20px;
  padding: 0 10px;
  pointer-events: none;
  left: 15px;
  color: #9892A3;
  transition: all 0.3s;
  font-size: 18px;
  line-height: 160%;
  letter-spacing: 0.8px;
  z-index: 1;
}
.character-counter {
  position: absolute;
  right: 25px;
  bottom: 25px;
  color: #627DFF;
}
.character-counter-error {
  color: #F39494;
}
.input-container {
  width: 100%;
  position: relative;
  height: 15rem;
}
.generic-input-label-shrink {
  top: 0;
  transform-origin: left top;
  font-size: 14px;
  background: #FFFFFF;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.04);
  border-radius: 4.57831px;
}
.generic-input-label-error {
  color: #F39494;
}
.generic-input-label-focus {
  color: #627DFF;
}
.generic-input {
  width: 100%;
  height: 90%;
  margin: 10px 0;
  background: rgba(0,0,0,0);
  box-sizing: border-box;
  outline: none;
  border: none;
  font-family: Avenir Next;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 160%;
  /* identical to box height, or 29px */

  letter-spacing: 0.2px;

  color: #2E293D;
}
.generic-input-container {
  width: 100%;
  height: 90%;
  margin: 10px 0;
  background: #EBEFFF;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 15px 15px 40px 15px;
}
.generic-input-container-error {
  background: #FFECEC;
}
.generic-input-container-focus {
  background: #D9E0FF;
}
.input-error-text {
  visibility: hidden;
  align-self: flex-end;
  font-size: 14px;
  line-height: 160%;
  /* or 22px */

  letter-spacing: 0.2px;

  color: #F39494;
}
@media screen and (max-width: 992px) {
  .generic-input {
    font-size: calc(var(--oh) * 1.7);
  }
  .generic-input-container {
    margin: 0;
    border-radius: 6px;
    height: 100%;
    padding: 15px 15px 25px;
  }
  .generic-input-label {
    font-size: calc(var(--oh) * 1.7);
  }
  .generic-input-label-shrink {
    font-size: calc(var(--oh) * 1.2);
    top: calc(var(--oh) * -0.9);
  }
  .input-error-text {
    font-size: calc(var(--oh) * 1.2);
  }
  .input-container {
    height: calc(var(--vh) * 23);
  }
  .character-counter {
    bottom: 10px;
  }
}

</style>