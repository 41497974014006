<template>
  <div class="cookie-consent-container" ref="containerRef">
    <img class="cookie-icon" src="../../assets/cookie-logo.png" />
    <div class="cookie-consent-text" v-html="$t('COOKIE_CONSENT_TEXT')" />
    <a class="cookie-consent-button" @click="setCookieConsent">OK</a>
  </div>
</template>

<script>
import { onMounted, ref, inject, watch } from "vue";
import { useRoute } from "vue-router";

import cookieHelper from "@/utils/helpers/cookieHelper";
export default {
  name: "CookieConsent",
  setup() {
    const containerRef = ref(null);
    const global = inject("global");
    const route = useRoute();

    const approvedCookieConsent = {
      preferences: true,
      statistics: true,
      marketing: true
    }

    const checkCookieConsent = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag(){window.dataLayer.push(arguments);}
      global.setCookieConsent(cookieHelper.getConsentCookie());
      if(global.state.cookieConsent.preferences && global.state.cookieConsent.statistics && global.state.cookieConsent.marketing) {
        containerRef.value.classList.add("approved")
        gtag('consent', 'update', {
          'ad_storage': 'granted',
          'analytics_storage': 'granted'
        });

        window.dataLayer.push({
          'event': 'update_consent'
        });

      } else {
        containerRef.value.classList.remove("approved")
      }
    }

    watch(() => route.path, checkCookieConsent)

    onMounted(() => {
      checkCookieConsent()
    })
    const setCookieConsent = () => {
      cookieHelper.setConsentCookie(approvedCookieConsent)
      checkCookieConsent()
    }

    return {
      setCookieConsent,
      containerRef
    }
  }
}
</script>

<style scoped>
.cookie-consent-container {
  position: fixed;
  width: 100vw;
  background-color: white;
  bottom: 0;
  display: flex;
  align-items: center;
  padding: 14px 10%;
  box-sizing: border-box;
  z-index: 999999999;
}
.cookie-consent-container.approved {
  display: none;
}
.cookie-icon {
  height: 32px;
  width: 32px;
  margin-right: 40px;
}
.cookie-consent-text {
  font-size: 13px;
  line-height: 18px;
  margin-right: 20%;
  text-align: start;
}
.cookie-consent-button {
  font-size: 13px;
  text-decoration: none;
  border: 2px solid #EAEAEA;
  border-radius: 6px;
  line-height: 28px;
  padding: 0 33px;
}
@media screen and (max-width: 992px) {
  .cookie-consent-container {
    flex-direction: column;
  }
  .cookie-icon {
    margin-right: 0;
    margin-bottom: 15px;
  }
  .cookie-consent-text {
    margin-right: 0;
    margin-bottom: 15px;
  }
  .cookie-consent-button {
    width: 80%;
  }
}
</style>