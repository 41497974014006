<template>
  <div class="custom-footer">
    <div class="custom-footer-left">
      <div v-for="item in menuItems" v-bind:key="item.content" class="footer-menu-item">
        <h4 class="footer-item-text" @click="item.onClick">{{$t(item.content)}}</h4>
      </div>
    </div>
    <div class="spacer" />
    <div class="custom-footer-right">
      <span>{{$t("COPYRIGHT_TEXT")}}</span>
    </div>

  </div>
</template>

<script>
export default {
  name: "GenericFooter",
  props: {
    menuItems: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style scoped>
  .custom-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #9892a3;
    bottom: 0;
    right: 0;
    padding: 55px 10px;
    margin-top: 190px;
    background: #F5F7FE;
    position: relative;
  }

  .custom-footer-left {
    display: flex;
  }
  .custom-footer-right span {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 234.67%;
    /* or 42px */


    color: #C8C8C8;
  }

  .footer-menu-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0 30px;
  }

  .footer-item-text {
    cursor: pointer;
    padding: 10px 0;
    margin: 0;
    font-size: 16px;
    font-weight: 500;
  }

  .spacer {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 373px;
  }


  @media screen and (max-width: 1150px) {
    .custom-footer {
      flex-direction: column;
      line-height: initial;
    }

  }
  @media screen and (max-width: 992px) {
    .custom-footer-left {
        flex-direction: column;
    }
    .custom-footer {
      align-items: flex-start;
      margin-top: 0;
    }
    .custom-footer-left span {
      font-weight: 600;
      font-size: 14px;
      line-height: 234.67%;
    }
    .custom-footer-right {
      padding: 10px 0;
    }
    .custom-footer-right span {
      font-weight: 500;
      font-size: 12px;
      line-height: 234.67%;
      margin-left: 30px;
    }
  }
</style>