<template>
  <div>
    <generic-modal :open="open" :on-close="closeModal" background="white">
      <div class="contact-modal-header">
        <h3 class="contact-us-heading">{{$t("CONTACT_US")}}</h3>
        <h3 class="modal-close-button" @click="closeModal">X</h3>
      </div>
      <div class="contact-modal-content">
        <generic-input :ref="setValidationRef" required color="#334f7a" v-model="email" type="email" label="EMAIL" />
        <generic-text-area :ref="setValidationRef"  required color="#334f7a" v-model="message" label="TYPE_YOUR_MESSAGE" />
      </div>
      <div class="contact-modal-footer">
        <generic-button label="SEND" color="#627DFF" :style="global.state.isMobile ? { margin: 0, padding: '1.4vh 11vw' } : {}" :disabled="formSubmitted || sendMailLoading" @on-click="onSubmit" />
        <div class="submit-message-container" ref="submitTextRef">
          <svg-icon class="submit-form-icon" icon="check" :size="26" />
          <span class="submit-form-info">{{$t(sendMailLoading ? "SUBMIT_FORM_LOADING" : (sendMailFail ? "SUBMIT_FORM_FAIL" : "SUBMIT_FORM_SUCCESS"))}}</span>
        </div>
      </div>
    </generic-modal>
  </div>

</template>

<script>
import GenericModal from "@/components/generic/GenericModal";
import GenericInput from "@/components/generic/GenericInput";
import GenericTextArea from "@/components/generic/GenericTextArea";
import GenericButton from "@/components/generic/GenericButton";
import GenericSvgIcon from "@/components/generic/GenericSvgIcon";
import { ref, onBeforeUpdate, inject } from "vue";
import animations from "../../utils/animations";
export default {
  name: "ContactModal",
  components: {
    "generic-modal": GenericModal,
    "generic-input": GenericInput,
    "generic-text-area": GenericTextArea,
    "generic-button": GenericButton,
    "svg-icon": GenericSvgIcon
  },
  props: {
    open: {
      type: Boolean,
      default: false
    },
    setOpen: {
      type: Function,
      default: () => {}
    }
  },
  setup(props) {
    const email = ref("");
    const message = ref("");
    const submitTextRef = ref(null);
    let validationRef = [];
    const sendMailLoading = ref(false);
    const sendMailFail = ref(false);
    const formSubmitted = ref(false);
    const global = inject("global");

    onBeforeUpdate(() => {
      validationRef = []
    })

    function setValidationRef(el) {
      validationRef.push(el)
    }

    function onSubmit() {
      validationRef.forEach(item => item.validate())
      const valid = validationRef.every(item => {
        return !item.isError
      })
      if(!valid) {
        validationRef.forEach(item => {
          animations.shake(item.$el.getElementsByClassName("input-error-text")[0])
        })
      } else {
        sendMail();
        animations.appear(document.getElementsByClassName("submit-message-container")[0])
      }
    }
    function closeModal() {
      email.value = "";
      message.value = "";
      formSubmitted.value = false;
      props.setOpen(false);
    }

    function sendMail() {
      sendMailLoading.value = true
      submitTextRef.value.classList.add("submit-form-loading")
      fetch("https://email-api.cdwapi.com/send", {
        method: "POST",
        body: JSON.stringify({
          contactEmail: email.value,
          to: "scanner@codeway.co",
          body: message.value,
          subject: "Website Contact Form Submission"
        }),
        headers: {
          "Authorization": "1AV7ok3DwQFmUMx4pEsuXEvDMakzZIQmpSJVoApNi6vJe4pPs6JcmCr7tPkU0SxuvZxPwRDlG77ASGb5KHOUDmxNgRTeB6RhW8bo",
          "Content-Type": "application/json"
        }
      }).then(response => {
          submitTextRef.value.classList.remove("submit-form-loading")
          if(response.status === 200) {
            submitTextRef.value.classList.add("submit-form-success")
          } else {
            sendMailFail.value = true
            submitTextRef.value.classList.add("submit-form-fail")
          }
         sendMailLoading.value = false
          formSubmitted.value = true
          setTimeout(() => {
            closeModal()
          },2500)
      }).catch(() => {
          sendMailFail.value = true
          submitTextRef.value.classList.remove("submit-form-loading")
          submitTextRef.value.classList.add("submit-form-fail")
          sendMailLoading.value = false
          formSubmitted.value = true
          setTimeout(() => {
            closeModal()
          },2500)
      })
    }
    return {
      email,
      message,
      setValidationRef,
      onSubmit,
      closeModal,
      sendMailLoading,
      sendMailFail,
      submitTextRef,
      formSubmitted,
      global
    }
  }
}
</script>

<style scoped>
.contact-modal-header {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 20px;
}
.contact-modal-footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.contact-modal-content {
  flex-grow: 1;
  width: 100%;
  margin-top: 35px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.contact-us-heading {
   font-weight: bold;
   font-size: 45px;
   line-height: 150%;
   text-align: center;

   color: #2E293D;
 }
h3 {
  margin: 0;
}
.submit-message-container {
  display: flex;
  align-items: center;
  visibility: hidden;
}
.submit-form-info {
  font-weight: 600;
  font-size: 18px;
  margin-left: 7px;
  line-height: 160%;
  /* identical to box height, or 29px */

  letter-spacing: 0.2px;

}
.submit-form-loading .submit-form-icon, .submit-form-fail .submit-form-icon {
  display: none!important;
}

.submit-form-fail {
  visibility: visible;
  color: #ba000c;
}

.submit-form-success {
  visibility: visible;
  color: #9892A3;
}

.submit-form-loading {
  visibility: visible;
  color: #9892A3;

}

.modal-close-button {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 23px;
  color: #9892A3;
  transform: translateY(-50%);
}

@media screen and (max-width: 992px) {
  .contact-modal-header {
    margin: 13px 0;
  }
  .modal-close-button {
    font-size: calc(var(--oh) * 2);
  }
  .contact-us-heading {
    font-size: calc(var(--oh) * 3.3);
  }
  .contact-modal-content {
    margin-top: 0;
  }
  .submit-form-info {
    font-size: calc(var(--oh) * 1.6);
  }
  .submit-message-container {
    margin-top: calc(var(--oh) * 1);;
  }
}
</style>