<template>
  <a
      class="custom-button"
      ref="buttonRef"
      @click="$emit('on-click', href)"
      :style="{
        borderRadius: '10px',
        border: !simple ? '2px solid ' + color : '',
        pointerEvents: disabled ? 'none' : '',
        padding: dense ? '5px 8px' : '15px 30px',
        background: simple || bordered ? 'rgba(0,0,0,0)' : color,
        '--icon-color' : simple || bordered ? color : textColor,
        fontSize: 16 * size + 'px'
      }"
  >
    <svg-icon v-if="icon" :icon="icon" :size="iconSize * size" class="btn-icon" />
    <span ref="spanRef" :style="{ color: simple || bordered ? color : textColor, fontWeight: simple ? 500 : 600}">{{ $t(label)}}</span>
  </a>
</template>

<script>
import { ref } from "vue";
import GenericSvgIcon from "@/components/generic/GenericSvgIcon";
export default {
  name: "CustomButton",
  components: {
    "svg-icon": GenericSvgIcon
  },
  emits: [
      "on-click"
  ],
  props: {
    label: {
      type: String,
      required: true,
      default: "Button"
    },
    href: {
      type: String,
      default: "javascript:void(0);"
    },
    onClick: {
      type: Function,
      default: () => {}
    },
    simple: {
      type: Boolean,
      default: false
    },
    bordered: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: "#647280"
    },
    disabled: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String
    },
    iconSize: {
      type: Number
    },
    textColor: {
      type: String,
      default: "white"
    },
    size: {
      type: Number,
      default: 1
    }
  },
  setup(props) {
    const buttonRef = ref(null);
    const spanRef = ref(null);
    const iconColor = ref(!props.simple && !props.bordered ? props.textColor : props.color);
    const bgPosition = ref("right!important");
    /*
    onMounted(() => {
      if(!props.simple && !props.bordered) {

        buttonRef.value.animate([{
          backgroundPosition: props.simple || props.bordered ? "right" : "left",
          color: props.simple || props.bordered ? props.color : props.textColor,
          "--icon-color": props.textColor,
          opacity: 1
        }], {
          duration: 1000,
          iterations: 1,
          fill: "forwards"
        })

        spanRef.value.animate([{
          color: props.textColor
        }], {
          duration: 1000,
          iterations: 1,
          fill: "forwards"
        })

        bgPosition.value = "left!important"

      } else {
        buttonRef.value.animate([{
          opacity: 1
        }], {
          duration: 1000,
          iterations: 1,
          fill: "forwards"
        })
      }
    })

     */
    return {
      buttonRef,
      spanRef,
      iconColor,
      bgPosition
    }
  }
}
</script>

<style scoped>
  .custom-button {
    margin: 10px;
    padding: 17px 40px;
    cursor: pointer;
    text-decoration: none;
    display: inline-flex;
    align-items: flex-end;
    justify-content: center;
    background-size: 205% 100%!important;
    background-position: right;
  }

  :deep(path) {
    fill: var(--icon-color);
    stroke: var(--icon-color)!important;
    transition: 0.3s linear;
  }
</style>