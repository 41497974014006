<template>
  <mobile-menu v-if="global.state.isMobile" class="scanner-mobile-menu" :menu-items="middleMenu" />
  <generic-header v-else ref="headerRef" :left-menu="leftMenu" :right-menu="rightMenu" :middle-menu="middleMenu" :isFixed="isFixed" :is-fixed="isFixed" @navigate-home="navigateHome" class="scanner-header"/>
</template>

<script>
import { ref, onUnmounted, onMounted, inject, defineAsyncComponent } from "vue";
import { useRouter } from "vue-router";
import throttle from "lodash.throttle"

import animations from "@/utils/animations";
import constants from "@/utils/constants";

export default {
  name: "ScannerHeader",
  components: {
    "mobile-menu": defineAsyncComponent(() => import("@/components/mobile/MobileMenu")),
    "generic-header": defineAsyncComponent(() => import("@/components/generic/GenericHeader"))
  },
  setup() {
    const router = useRouter();
    const global = inject("global");
    const $ = require("jquery");

    let badgesVisible = false;
    const headerRef = ref(null);
    const isFixed = ref(false);

    function navigateHome(href) {
      if(href) {
        router.push("/" + href)
        window.dispatchEvent(new HashChangeEvent("hashchange"))
      } else {
        router.push("/")
      }
      // if(window.location.pathName !== "/") {
      // } else {
      //   window.dispatchEvent(new HashChangeEvent("hashchange"))
      // }
    }

    function areBadgesInViewport () {
      const el = document.getElementById("banner-badge-container")
      if(!el || window.location.pathname !== "/")
        return false
      const rect = el.getBoundingClientRect();
      return (
          rect.top >= 0 &&
          rect.left >= 0 &&
          rect.bottom <= $(window).height() &&
          rect.right <= $(window).width()
      );
    }

    const handleScroll = () => {
      const currentBadgesVisible = areBadgesInViewport()
      const scrollTop = global.state.isSafari ? document.body.scrollTop : document.scrollingElement.scrollTop
      const height = $(window).height()

      if(((window.location.pathname === "/" && scrollTop >= height * 2) || (window.location.pathname !== "/" && scrollTop > 0)) && !currentBadgesVisible) {

        if(currentBadgesVisible !== badgesVisible) {
          animations.headerOpen(headerRef.value.$el)
          badgesVisible = currentBadgesVisible
        } else if(isFixed.value === false)
          animations.headerOpen(headerRef.value.$el)

        isFixed.value = true

      } else if(isFixed.value) {

        if(badgesVisible !== currentBadgesVisible && currentBadgesVisible === false) {
          animations.headerClose(headerRef.value.$el, headerRef.value.$el.clientHeight)
          badgesVisible = currentBadgesVisible
        } else
          isFixed.value = false

      }
    }

    const debouncedHandleScroll = throttle(handleScroll,200, {leading: true, trailing: true})
    onMounted(() => {
      setTimeout(() => {
        if(!global.state.isMobile) {
          if(global.state.isSafari) {
            document.body.addEventListener("scroll", debouncedHandleScroll);
          } else {
            document.addEventListener("scroll", debouncedHandleScroll);
          }
        }
      }, 150)

    })
    onUnmounted(() => {
      if(!global.state.isMobile) {
        if(global.state.isSafari) {
          document.body.removeEventListener("scroll", debouncedHandleScroll);
        } else {
          document.removeEventListener("scroll", debouncedHandleScroll);
        }
      }
    })
    return {
      navigateHome,
      headerRef,
      leftMenu: constants.webHeaderItems.leftMenu,
      rightMenu: constants.webHeaderItems.rightMenu,
      middleMenu: constants.webHeaderItems.middleMenu,
      isFixed,
      global
    }
  }
}
</script>

<style scoped>

</style>