<template>
  <div v-if="open" class="modal-wrapper">
    <div class="modal-background"  @click="onClose"/>
    <div class="modal-container" :style="sizeModeAuto ? { background, width: 'auto', height: 'auto'} : { background }" >
      <slot />
    </div>
  </div>
</template>

<script>
import { watch } from "vue"
export default {
  name: "GenericModal",
  props: {
    open: {
      type: Boolean,
      default: false
    },
    onClose: {
      type: Function,
      default: () => {}
    },
    background: {
      type: String,
      default: "white"
    },
    sizeModeAuto: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    var keys = {37: 1, 38: 1, 39: 1, 40: 1};

    function preventDefault(e) {
      e.preventDefault();
    }
    function preventDefaultForScrollKeys(e) {
      if (keys[e.keyCode]) {
        preventDefault(e);
        return false;
      }
    }

    var supportsPassive = false;
    try {
      window.addEventListener("test", null, Object.defineProperty({}, 'passive', {
        get: function () { return supportsPassive = true; }
      }));
    } catch(e) { console.log(e)}

    var wheelOpt = supportsPassive ? { passive: false } : false;
    var wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';

    function disableScroll() {
      window.addEventListener('DOMMouseScroll', preventDefault, false); // older FF
      window.addEventListener(wheelEvent, preventDefault, wheelOpt); // modern desktop
      window.addEventListener('touchmove', preventDefault, wheelOpt); // mobile
      window.addEventListener('keydown', preventDefaultForScrollKeys, false);
    }

    function enableScroll() {
      window.removeEventListener('DOMMouseScroll', preventDefault, false);
      window.removeEventListener(wheelEvent, preventDefault, wheelOpt);
      window.removeEventListener('touchmove', preventDefault, wheelOpt);
      window.removeEventListener('keydown', preventDefaultForScrollKeys, false);
    }
    watch(() => props.open, value => {
      if(value) {
        disableScroll()
      }
      else {
        enableScroll()
      }

    })
  }
}
</script>

<style scoped>
.modal-wrapper {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999999999;
}

.modal-background {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  z-index: 1;
}

.modal-container {
  z-index: 2;
  width: min(588px, 90%);
  height: min(688px, 90%);
  border-radius: 25px;
  padding: 45px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 992px) {
  .modal-container {
    width: calc(100% - 20px);
    margin: 0 10px;
    height: calc(var(--oh) * 48 + 46px);
    padding: calc(var(--oh) * 3.3) 7vw;
    border-radius: 15px;
  }
}
</style>