export default {
    float: el => {
        el.animate( [
            {
                transform: 'translate(0px,0px)',
                transformOrigin: "-50% 50px"
            },
            {
                transform: 'translate(' + (Math.random() * 40 - 20) + 'px,' + (Math.random() * 40 - 20) + 'px)'
            },
            {
                transform: 'translate(' + (Math.random() * 40 - 20) + 'px,' + (Math.random() * 40 - 20) + 'px)'
            },
            {
                transform: 'translate(' + (Math.random() * 40 - 20) + 'px,' + (Math.random() * 40 - 20) + 'px)'
            },
            {
                transform: 'translate(0px,0px)'
            }
        ],
        {
           duration: 12000,
           iterations: Infinity
        })
    },
    fadeIn: el => {
        el.animate([
            {
                transform: 'scale(0.5,0.5)',
                opacity: '0'
            },
            {
                transform: 'scale(1,1)',
                opacity: '1'
            }
        ],
            {
                duration: 400,
                iterations: 1
            })
    },
    appearUp: el => {
      el.animate([
          {
              opacity: 0,
              transform: "translateY(150px) scale(0.5)"
          },
          {
              opacity: 1,
              transform: "none"
          }
      ], {
          duration: 1000,
          iterations: 1,
          easing: "ease",
          fill: "forwards"
      })
    },
    disappearDown: el => {
        el.animate([
            {
                opacity: 1,
                transform: "none"
            },
            {
                opacity: 0,
                transform: "translateY(150px) scale(0.5)"
            }
        ], {
            duration: 1000,
            iterations: 1,
            easing: "ease",
            fill: "forwards"
        })
    },
    slideLeftEnter: el => {
        el.animate([
                {
                    transform: 'translate(100%,0)',
                },
                {
                    transform: 'translate(0,0)',
                }
            ],
            {
                duration: 250,
                iterations: 1,
                easing: "ease-in-out",

                fill: "forwards"
            })
    },
    slideLeftLeave: el => {
        el.animate([
                {
                    transform: 'translate(0,0)',
                },
                {
                    transform: 'translate(-100%,0)',
                }
            ],
            {
                duration: 250,
                iterations: 1,
                easing: "ease-in-out",

                fill: "forwards"
            })
    },
    slideRightEnter: el => {
        el.animate([
                {
                    transform: 'translate(-100%,0)',
                },
                {
                    transform: 'translate(0,0)',
                }
            ],
            {
                duration: 250,
                iterations: 1,
                easing: "ease-in-out",
                fill: "forwards"
            })
    },
    slideRightLeave: el => {
        el.animate([
                {
                    transform: 'translate(0,0)',
                },
                {
                    transform: 'translate(100%,0)',
                }
            ],
            {
                duration: 250,
                iterations: 1,
                easing: "ease-in-out",
                fill: "forwards"
            })
    },
    headerOpen: el => {
        el.animate([
            {
                maxHeight: "0",
                opacity: 0
            },
            {
                maxHeight: "20vh",
                opacity: 1
            }
        ], {
            duration: 1000,
            iterations: 1,
            fill: "forwards"
        })
    },
    headerClose: (el) => {
        el.animate([
            {
                maxHeight: "72px",
                opacity: 1
            },
            {
                maxHeight: "0",
                opacity: 0
            }
        ], {
            duration: 400,
            iterations: 1,
            fill: "forwards"
        })
    },
    accordionOpen: el => {
        const closedHeight = el.children[0].clientHeight
        el.animate([
            {
              maxHeight: closedHeight + "px"
            },
            {
                maxHeight: Array.from(el.children).reduce((acc, el) => acc + el.offsetHeight, 0) + 30 + "px"
            }
        ], {
            iterations: 1,
            duration: 400,
            fill: "forwards",
            easing: "ease-in-out"
        })
    },
    accordionClose: el => {
        const closedHeight = el.children[0].clientHeight
        el.animate([
            {
                maxHeight: el.offsetHeight + "px"
            },
            {
                maxHeight: closedHeight + "px"
            }
        ], {
            iterations: 1,
            duration: 400,
            easing: "ease-in-out",
            fill: "forwards"
        })
    },
    expandCircle: el => {
        el.animate([
            {
                transform: "translate(-50%,50%) scale(0)",
                opacity: 0
            },
            {
                transform: "translate(-50%,50%) scale(0.5)",
                opacity: 1
            },
            {
                transform: "translate(-50%,50%) scale(1)",
                opacity: 0
            }
        ], {
            iterations: 1,
            duration: 3000
        })
    },
    shake: el => {
        el.animate([
            {
                transform: "translate(0, 0)"
            },
            {
                transform: "translate(-4%, 0)"
            },
            {
                transform: "translate(0, 0)"
            },
            {
                transform: "translate(4%, 0)"
            },
            {
                transform: "translate(0, 0)"
            }
        ], {
            iterations: 3,
            duration: 100
        })
    },
    appear: el => {
        el.animate([
            {
                transformOrigin: "50% 50%",
                transform: "scale(0)"
            },
            {
                transform: "scale(1)"
            }
        ], {
            iterations: 1,
            duration: 250,
            composite: "add",
            fill: "forwards"
        })
    }
}