<template>
  <div class="navbar-container">
    <nav class="navbar">
      <template v-if="!isDocumentDetail">
        <div class="home-button" @click="navigateHome">
          <img class="home-button-logo" src="../../assets/scanner-logo.png" />
          <span class="home-button-text">{{$t("COMPANY_NAME")}}</span>
        </div>
      </template>
      <template v-else>
        <div class="back-button" @click="backToDocuments">
          <img class="back-button-logo" src="../../assets/document-back.png" />
          <span class="back-button-text">{{ $t("BACK_TO_DOCUMENTS") }}</span>
        </div>
      </template>
      <div class="navbar-right">
        <div class="navbar-button" @click="toggleDropdown">
          <svg-icon icon="download" :size="19" />
          <span>{{downloadTriggered ? $t("IS_DOWNLOADING" ) : $t("DOWNLOAD")}}</span>
          <div class="navbar-button-dropdown-container" :style="{ display: dropdownOpen ? 'flex' : 'none'}">
            <div class="navbar-button-dropdown-triangle" />
            <div class="navbar-button-dropdown">
              <div class="navbar-button-dropdown-item" name="PDF" @click="triggerDownload">{{$t("DOWNLOAD_PDF_FILE")}}</div>
              <hr />
              <div class="navbar-button-dropdown-item" name="JPG" @click="triggerDownload">{{$t("DOWNLOAD_JPG_FILE")}}</div>
            </div>
          </div>
        </div>
        <store-badges :scale="0.635" class="navbar-badges" />
      </div>
    </nav>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { inject, ref, watch } from "vue";

import GenericSvgIcon from "@/components/generic/GenericSvgIcon";
import StoreBadges from "@/components/common/StoreBadges";

export default {
  name: "FunctionalNavbar",
  components: {
    "svg-icon": GenericSvgIcon,
    "store-badges": StoreBadges
  },
  setup() {
    const global = inject("global");
    const router = useRouter();
    const downloadTriggered = ref(false);
    const isDocumentDetail = ref(false);
    const dropdownOpen = ref(false);
    watch(() => global.state.downloadTriggered, value => {
      downloadTriggered.value = value
    })

    watch(() => global.state.isDocumentDetail, value => {
      isDocumentDetail.value = value
    })

    const navigateHome = () => router.push("/");

    const backToDocuments = () => global.setIsDocumentDetail(false)
    const triggerDownload = e => {
      global.setDownloadTriggered(e.target.getAttribute("name"))
    }
    const toggleDropdown = () => {
      dropdownOpen.value = !dropdownOpen.value
    }
    return { navigateHome, global, downloadTriggered, triggerDownload, dropdownOpen, toggleDropdown, isDocumentDetail, backToDocuments }
  }
}
</script>

<style scoped>
.navbar-container {
  width: 100vw;
  background-color: #FFFFFF;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.06);
  padding: 14px 60px;
  box-sizing: border-box;
  z-index: 10;
}
.navbar {
  display: flex;
  justify-content: space-between;
}
.home-button {
  height: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.home-button-logo {
  height: 100%;
}
.home-button-text {
  margin-left: 12px;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  color: #2E293D;
}

.back-button {
  height: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.back-button-logo {
  height: 14px;
}

.back-button-text {
  margin-left: 30px;
  font-weight: 600;
  font-size: 16px;
  line-height: 15px;

  color: #2E293D;
}

.navbar-right {
  display: flex;
}

.navbar-button {
  position: relative;
  height: 40px;
  box-sizing: border-box;
  padding: 10px 27px;
  display: flex;
  align-items: center;
  background: #627DFF;
  border-radius: 8px;
  cursor: pointer;
}
.navbar-button span {
  margin-left: 12px;
  color: #FFFFFF;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 138.8%;
  text-align: center;
}
.navbar-badges {
  padding: 0;
  margin-left: 15px;
}

.navbar-button-dropdown-container {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  transform: translateY(calc(100% + 20px));
  display: flex;
  flex-direction: column;
  align-items: center;

}
.navbar-button-dropdown {
  border-radius: 8px;
  background-color: #FFFFFF;
  flex-shrink: 0;
  width: 100%;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
}

.navbar-button-dropdown hr {
  border: none;
  border-top: 0.5px solid rgba(0,0,0,0.1);
  margin: 0;
}

.navbar-button-dropdown-item {
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #2C293D;
  padding: 15px 0;
  cursor: pointer;
}

.navbar-button-dropdown-triangle {
  width: 0;
  height: 0;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;

  border-bottom: 12px solid #FFF;
 filter: drop-shadow(0 4px 20px rgba(0, 0, 0, 0.06));

}

@media screen and (max-width: 992px) {
  .navbar-container {
    display: none;
  }
}
</style>