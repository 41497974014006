<template>
  <div v-html="require(`./../../assets/icons/icon-${icon}.svg`)" style="display: flex; align-items: flex-end;" :style="{ '--iconSize': size + 'px'}"></div>
</template>

<script>
export default {
  name: "GenericSvgIcon",
  props: {
    icon: {
      type: String,
      default: "apple"
    },
    size: {
      type: Number,
      default: 1.3
    }
  }
}
</script>

<style scoped>
  :deep(svg[class^="svg-icon-"]) {
    height: var(--iconSize);
    width: var(--iconSize);

  }
</style>