import { createI18n } from 'vue-i18n/dist/vue-i18n.esm-bundler.js';

const loadMessages = () => {
  const locales = require.context('@/locales', true, /[\w-]+\.json$/i)
  return locales.keys().reduce((locs, loc) => ({ ...locs, [loc.replace(/\.|\/|json/g, '')]: locales(loc) }), {})
}

export default createI18n({
  locale: 'en',
  fallbackLocale: 'en',
  isGlobal: true,
  messages: loadMessages(),
  silentTranslationWarn: true,
  warnHtmlInMessage: "off"
})