import { createWebHistory, createRouter } from "vue-router";

const Sections = () => import("@/pages/routes/Sections");
const FAQ = () => import("@/pages/routes/FAQ");
const Terms = () => import("@/pages/routes/TermsConditions");
const Privacy = () => import("@/pages/routes/PrivacyPolicy");
const Cookie = () => import("@/pages/routes/CookiePolicy");
const BlogPage = () => import("@/pages/routes/BlogPage");
const ErrorNotFound = () => import("@/pages/routes/ErrorNotFound");
const DocumentPage = () => import("@/pages/routes/DocumentPage");
const ExpiredDocument = () => import("@/pages/routes/DocumentPage/ExpiredDocument");

const routes = [
    {
        path: "/",
        name: "Sections",
        component: Sections,
    },
    {
        path: "/faq",
        name: "FAQ",
        component: FAQ,
    },
    {
        path: "/terms-conditions",
        name: "TermsConditions",
        component: Terms,
    },
    {
        path: "/privacy-policy",
        name: "PrivacyPolicy",
        component: Privacy,
    },
    {
        path: "/cookie-policy",
        name: "CookiePolicy",
        component: Cookie,
    },
    {
        path: "/blog/:blogId",
        name: "BlogPage",
        component: BlogPage
    },
    {
        path: "/document/:documentId",
        name: "Document",
        component: DocumentPage
    },
    {
      path: "/expired-document",
      name: "DocumentExpired",
      component: ExpiredDocument
    },
    {
        path: "/:pathMatch(.*)*",
        name: "404",
        component: ErrorNotFound
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to) {
        if(to.hash) {
            return { selector: to.hash}
        }
        return { x: 0, y: 0}
    }
});

router.beforeEach(function (to, from,next) {
    next();
    // setTimeout(() => {
        // if(to.path !== from.path) {
            setTimeout(() => {
                const bodyScrolling = navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
                    navigator.userAgent &&
                    navigator.userAgent.indexOf('CriOS') == -1 &&
                    navigator.userAgent.indexOf('FxiOS') == -1 && window.innerWidth > 992;
                const container = bodyScrolling ? 'body' : 'scrollingElement'
                if(window.location.hash) {
                    document[container].scrollTop = document.getElementById(window.location.hash.substring(1)).getBoundingClientRect().top + document[container].scrollTop
                } else {
                    document[container].scrollTop = 0

                }

            }, 200)
        // }
    // }, 100)

});

export default router;