export default {
    getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) {
            return parts.pop().split(';').shift();
        } else {
            return undefined;
        }
    },
    deleteCookie(name) {
        document.cookie = `${name}= ; expires = Thu, 01 Jan 1970 00:00:00 GMT`
    },
    setCookie(name, value) {
        document.cookie = `${name}=${JSON.stringify(value)};  expires=Tue, 19 Jan 2038 03:14:07 UTC;`
    },
    defaultConsent: {
        preferences: false,
        statistics: false,
        marketing: false
    },
    getConsentCookie() {
        try {
            const consentCookie =  JSON.parse(this.getCookie("CookieConsent"));
            if(typeof consentCookie !== "object" || !Object.prototype.hasOwnProperty.call(consentCookie, "preferences") || !Object.prototype.hasOwnProperty.call(consentCookie, "statistics") || !Object.prototype.hasOwnProperty.call(consentCookie, "marketing")) {
                this.setConsentCookie(this.defaultConsent)
                return this.defaultConsent
            }
            return consentCookie
        } catch {
            this.setConsentCookie(this.defaultConsent)
            return this.defaultConsent
        }
    },
    setConsentCookie(value) {
        this.setCookie("CookieConsent", value)
    }
}