<template>
  <generic-footer :menu-items="menuItems" />
  <contact-modal-e :open="contactModal" ref="modalRef" :set-open="() => setContactModal(false)" />
</template>

<script>
import { ref } from "vue";
import { useRouter } from "vue-router";

import GenericFooter from "@/components/generic/GenericFooter";
import ContactModal from "@/components/common/ContactModal";
export default {
  name: "ScannerFooter",
  components: {
    "generic-footer": GenericFooter,
    "contact-modal-e": ContactModal
  },
  setup() {
    const contactModal = ref(false)
    const modalRef = ref(null);
    function setContactModal(value) {
      contactModal.value = value
    }

    const router = useRouter();
    const navigate = path => {
      router.push(path)
    }
    const menuItems = ref([
      {
        content: "FAQ",
        onClick: () => navigate("/faq")

      },
      {
        content: "CODEWAY",
        onClick: () => {
          window.open("https://www.codeway.co", "_blank")
        }
      },
      {
        content: "PRIVACY_POLICY",
        onClick: () => navigate("/privacy-policy")
      },
      {
        content: "TERMS_CONDITIONS",
        onClick: () => navigate("/terms-conditions")
      },
      {
        content: "CONTACT_US",
        onClick: () => {
          setContactModal(true)
        }
      }
    ])
    return {
      menuItems,
      contactModal,
      setContactModal,
      modalRef
    }
  }
}
</script>

<style scoped>

</style>