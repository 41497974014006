import { reactive, readonly, computed } from "vue";

const state = reactive({
    isMobile: false,
    isSafari: false,
    cookieConsent: undefined,
    downloadTriggered: false,
    isDocumentDetail: false
});

const isBodyScrolling = computed(() => (state.isSafari && !state.isMobile))

const setIsMobile = function (isMobile) {
    state.isMobile = isMobile;
}

const setIsSafari = function (isSafari) {
    state.isSafari = isSafari;
}

const setCookieConsent = function (cookieConsent) {
    state.cookieConsent = cookieConsent
}

const setDownloadTriggered = function (downloadTrigger) {
    state.downloadTriggered = downloadTrigger
}

const setIsDocumentDetail = function (documentDetail) {
    state.isDocumentDetail = documentDetail
}

export default { state: readonly(state), isBodyScrolling: isBodyScrolling.value, setIsMobile, setIsSafari, setCookieConsent, setDownloadTriggered, setIsDocumentDetail };