<template>
    <scanner-header v-if="!isFunctionalLayout" />
    <functional-navbar v-else />
    <router-view />
    <div class="spacer" />
    <scanner-footer v-if="!isFunctionalLayout" />
  <cookie-consent />
</template>

<script>
import { onMounted, watch, ref } from "vue";
//import { useI18n } from 'vue-i18n';
import debounce from "lodash.debounce";
import $ from "jquery";
import { useRoute } from "vue-router";

import FunctionalNavbar from "@/components/common/FunctionalNavbar";
import ScannerHeader from "@/components/common/ScannerHeader";
import ScannerFooter from "@/components/common/ScannerFooter";
import CookieConsent from "@/components/common/CookieConsent";
import global from "@/utils/global";

export default {
  name: 'App',
  components: {
    "scanner-header": ScannerHeader,
    "scanner-footer": ScannerFooter,
    "cookie-consent": CookieConsent,
    "functional-navbar": FunctionalNavbar
  },
  provide: {
    global
  },
  setup() {
    //useI18n();
    const isSafari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
        navigator.userAgent &&
        navigator.userAgent.indexOf('CriOS') == -1 &&
        navigator.userAgent.indexOf('FxiOS') == -1 &&
        navigator.userAgent.indexOf('OPT') == -1 &&
        navigator.userAgent.indexOf('OPiOS') == -1 &&
        navigator.userAgent.indexOf('EdgiOS') == -1 &&
        navigator.userAgent.indexOf('YaBrowser') == -1;

    global.setIsSafari(isSafari)


    const windowHeightCalculator = () => {
      window.$ = $
      const vh = $(window).outerHeight() * 0.01;
      const oh = navigator.userAgent.indexOf('FxiOS') > -1 ? vh + 1.45 :document.getElementById("viewport-calculator").offsetHeight * 0.01;

      document.documentElement.style.setProperty('--vh', `${vh}px`);
      document.documentElement.style.setProperty('--oh', `${oh}px`);


      if(global.state.isMobile !== $(window).width() <= 992) {
        global.setIsMobile($(window).width() <= 992)
      }
    }

    const debouncedWindowHeightCalculator = debounce(windowHeightCalculator,100, { leading: false, trailing: true})
    const route = useRoute();
    const isFunctionalLayout = ref(false);
    watch(() => route.path, value => {
      isFunctionalLayout.value = value.startsWith("/document");
    })
    onMounted( () => {
      windowHeightCalculator();
       $(window).width() > 992 && window.addEventListener("resize", debouncedWindowHeightCalculator);

      if(isSafari && $(window).width() > 992) {
        document.documentElement.style.overflow = "hidden"
        document.body.style.maxHeight = "100vh"
        document.body.style.overflowY = "scroll"
        document.body.style["-webkit-overflow-scrolling"] = "touch"
      }
    })

    setTimeout(() => {
      document.getElementById("scanner-plus").style.visibility = "visible";
    },100)

    return {
      isFunctionalLayout
    }
  }
}
</script>

<style>
#scanner-plus{
  font-family: Avenir Next;
  visibility: hidden;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  text-rendering: optimizeLegibility;
  text-align: center;
  color: #2c3e50;
  font-size: 16px;
  width: 100%;
  min-height: calc(var(--vh) * 100);
  background: url("./assets/background.jpg");
  background-size: 100% auto;
  position: relative;
  display: flex;
  flex-direction: column;
  user-select: none;
}

.spacer {
  flex-basis: 0;
  flex-grow: 1;
}

@media screen and (max-width: 992px) {
  #app {
    font-size: 12px;
  }
}
</style>
