export default {
    userBoxPositions: [
        { top: 37, left: 23, width: 70, color: "#E4F9F8", avatarTop: 17.15, avatarLeft: 6},
        { top: 50, left: 10, width: 75, color: "#E0E5FF", avatarTop: 12, avatarLeft: 2 },
        { top: 55, left: 25, width: 125, color: "#FFF9E1", avatarTop: 9, avatarLeft: 18 },
        { top: 68, left: 13, width: 55, color: "#FFF3F3", avatarTop: 18, avatarLeft: 9 },
        { top: 39, left: 65, width: 61, color: "#E5FDE3", avatarTop: 12.5, avatarLeft: 12.5 },
        { top: 42, left: 85, width: 70, color: "#E4F9F8", avatarTop: 0, avatarLeft: 7 },
        { top: 55, left: 70, width: 129, color: "#FFF3F3", avatarTop: 12, avatarLeft: 2 },
        { top: 70, left: 81, width: 62, color: "#E0E5FF", avatarTop: 18, avatarLeft: 12 },
        { top: 80, left: 64, width: 70, color: "#E0E5FF", avatarTop: 17, avatarLeft: 3 }
    ],
    webHeaderItems: {
        leftMenu: [
            {
                logo: true,
                path: "scanner-logo.png",
                label: "COMPANY_NAME"
            }
        ],
        middleMenu: [
            {
                label: "ABOUT",
                href: "#about",
                simple: true,
                dense: true,
                color: "#2E293D"
            },
            {
                label: "FEATURES",
                href: "#features",
                simple: true,
                dense: true,
                color: "#2E293D"
            },
            {
                label: "TESTIMONIALS",
                href: "#testimonials",
                simple: true,
                dense: true,
                color: "#2E293D"
            },
            {
                label: "BLOG",
                href: "#blog",
                simple: true,
                dense: true,
                color: "#2E293D"
            }
        ],
        rightMenu: [
            {
                type: "badges"
            }
        ]
    }
}